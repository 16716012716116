import { get } from "lodash";

/* Constants for API */
export const API_URL = process.env.REACT_APP_API_URL;
export const CONTENT_LIST = "/user/content-list";
export const FEATURED_CONTENT = "/user/featured-content";
export const ARTICLE_DETAIL = "/user/article";
export const FAQS_LIST = "/user/faqs";
export const RELATED_CATEGORY_ARTICLES = "/user/related-catgeory-articles";
export const RELEASE_DETAIL = "/user/release-note";
export const RELATED_RELEASES = "/user/related-release-notes";
export const USER_API = "/user";
export const SPOTIFY_USER_API = "/spotify-users";
export const AUTH = "/auth";
export const SIGNUP_API = "/signup";
export const ME = "/me";
export const ARTIST_API = "/artist";
export const ACTIVE_DISTRIBUTORS = "/active-distributors";
export const REPORT = "/report";
export const DISTRIBUTOR_REPORTS_API = "/distributor-reports";
export const DISTRIBUTOR_API = "/distributor";
export const OTHER_REPORTS_API = "/other-reports";
export const STREAMING = "/streaming-income";
export const SEND_REPORTS_API = "/send-reports";
export const ADMIN = "/admin";
export const WHITELABEL_INSTANCE = "/whitelabel/instance";
export const FUNDING_OPTION = "/offer-generation";
export const ARTIST_ESTIMATE = "/user-enrollment";
export const NAVIGATION_STATUS_API = "/navigation-status";
export const VERIFY_STREAMING_INCOME = "/update-streaming-income";
export const SIGNIN_API = "/signin";
export const SIGNIN_SECURE_API = "/secure-link";
export const FORGOT_PASS_API = "/forgot-password";
export const OFFERS_API = "/estimated-offer";
export const VALIDATE_EMAIL = "/validate-email";
export const FAST_SIGNUP = "/fast-signup";
export const REPORT_AVAILABILITY = "/report-availability";
export const SEGMENT_DATA = "/segment/data";
export const BID_GUIDANCE = "/bid-guidance";
export const PARTNER_ARTIST_API = "/partner/artist-search";
export const BID_SUBMIT = "/bid-submit";

export const HOME = "/";
const HOME_REGEX_STR = "^/$";
export const HOME_REGEX = new RegExp(HOME_REGEX_STR);
export const AGENT = "/offers";
export const AGENT_HOME = `${AGENT}/:agent_id`;
const AGENT_REGEX_STR = `${AGENT}/.*`;
export const AGENT_REGEX = new RegExp(AGENT_REGEX_STR);
export const ARTICLE_DESC = "/article-template";
const ARTICLE_REGEX_STR = `${ARTICLE_DESC}/.*`;
export const ARTICLE_REGEX = new RegExp(ARTICLE_REGEX_STR);
export const ARTICLE_TEMPLATE = `${ARTICLE_DESC}`;
export const RELEASE_DESC = "/release-template";
const RELEASE_REGEX_STR = `${RELEASE_DESC}/.*`;
export const RELEASE_REGEX = new RegExp(RELEASE_REGEX_STR);
export const RELEASE_TEMPLATE = `${RELEASE_DESC}`;
export const TESTIMONIAL_DESC = "/testimonial-template";
const TESTIMONIAL_REGEX_STR = `${TESTIMONIAL_DESC}/.*`;
export const TESTIMONIAL_REGEX = new RegExp(TESTIMONIAL_REGEX_STR);
export const TESTIMONIAL_TEMPLATE = `${TESTIMONIAL_DESC}`;
export const HOME_AGENT_REGEX = new RegExp(
  `(${HOME_REGEX_STR}|${AGENT_REGEX_STR | ARTICLE_REGEX_STR})`
);

export const ABOUT_US = "/our-mission";
export const FUNDING_NETWORK = "/funding-network";
export const FUNDING_NETWORK_ARTIST = "/funding-network/artist";
export const FUNDING_NETWORK_LABEL = "/funding-network/label";
export const PARTNERS = "/partners";
export const PRESS = "/news";
export const COMPETITOR = "/competitor-home";
export const WHY = "/how-it-works";
export const LOGIN = "/login";
export const TEST = "/test";
export const TESTIMONIALS = "#testimonials";
export const KNOWLEDGECENTER = "/knowledgecenter";
export const GLOSSARY = "/glossary";
export const FAQ = "/faq";
export const LABELHOME = "/labels";
export const VIDEOS = "/videos";
export const ARTICLE = "/knowledge-center";
export const ADVANCING_INDEPENDENCE = "/advancing-independence";
export const ARTIST_LIKE_YOU = "/artist-like";
export const LEARN_MORE = "/learn-more";
export const TERM_OF_USE = "/terms_of_use";
export const PRIVACY_POLICY = "/privacy-policy";
export const CALENDLY = "https://calendly.com/matthew-bb/30min-1?month=2023-04";
export const ARTIST_STORIES = "/testimonials";
export const ARTIST_SEARCH_ESTIMATES = "/artist-funding";
export const ARTIST_ENROLLMENT = "/get-artist-funding";
export const PUBLISHING = "/publishing";
export const PARTNER_DIRECTORY = "/multiple-offers";
export const BID = "/bid";
export const SINGLE_PAGE_SIGNUP = "/signup";
export const A2IMHOME = "/a2im";

const NAV_LINKS_HOME = [{ title: "Home", to: HOME, arrow: false, links: [] }];

export const PAGE_DATA = {
  [SINGLE_PAGE_SIGNUP]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [A2IMHOME]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [FUNDING_NETWORK]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [FUNDING_NETWORK_ARTIST]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [FUNDING_NETWORK_LABEL]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ABOUT_US]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [PARTNERS]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [PRESS]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTICLE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ADVANCING_INDEPENDENCE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTIST_LIKE_YOU]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTIST_STORIES]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [LEARN_MORE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTICLE_TEMPLATE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [TERM_OF_USE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [PRIVACY_POLICY]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [RELEASE_TEMPLATE]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [COMPETITOR]: {
    loginButton: {
      show: false,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [WHY]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [KNOWLEDGECENTER]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [GLOSSARY]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [FAQ]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [LABELHOME]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [VIDEOS]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTIST_SEARCH_ESTIMATES]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [ARTIST_ENROLLMENT]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
  [PARTNER_DIRECTORY]: {
    loginButton: {
      show: true,
      text: "Login",
    },
    navLinks: NAV_LINKS_HOME,
  },
};

export const YT_VIDEO_ID_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)|shorts?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;

export const YT_VIDEO_LINK_REGEX =
  /https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([\w-]{11})/;

export const YT_THUMBNAIL_URL =
  "https://img.youtube.com/vi/[VIDEO_ID]/sddefault.jpg";

export const YT_THUMBNAIL_SMALL =
  "https://img.youtube.com/vi/[VIDEO_ID]/mqdefault.jpg";
export const YT_EMBED_URL =
  "https://www.youtube-nocookie.com/embed/[VIDEO_ID]?rel=0&autoplay=1&mute=1&modestbranding=1";

export const GetIframeVideo = (props) => (
  <iframe
    className={props.className}
    title={props.title}
    srcDoc={`<style>
    body, .full {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    cursor: pointer;
    }
    .inner-play-icon{
      width: 70px;
      height: 70px;
      clip-path: url(#clipPlay);
      background-color: #ffffff44;
      backdrop-filter: blur(8px);
      border-radius: 99px;
    }
    </style>
    <div
    onClick={window.location.replace('${YT_EMBED_URL.replace(
      "[VIDEO_ID]",
      props.url.match(YT_VIDEO_ID_REGEX).pop()
    )}')}
    class='full'
    >
    <img
    src='${(props.small ? YT_THUMBNAIL_SMALL : YT_THUMBNAIL_URL).replace(
      "[VIDEO_ID]",
      props.url.match(YT_VIDEO_ID_REGEX).pop()
    )}'
    class='full'
    loading='lazy'
    />
    ${
      get(props, "themePlay")
        ? `<svg width="0" height="0" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="clipPlay"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.5 70C54.83 70 70.5 54.33 70.5 35C70.5 15.67 54.83 0 35.5 0C16.17 0 0.5 15.67 0.5 35C0.5 54.33 16.17 70 35.5 70ZM30.9748 24.7818L47.6808 33.5916L47.6834 33.5937C47.9734 33.7488 48.2108 33.9579 48.375 34.2027C48.5392 34.4475 48.625 34.7205 48.625 34.9977C48.625 35.2749 48.5392 35.5478 48.375 35.7926C48.2108 36.0374 47.9734 36.2465 47.6834 36.4017L30.9775 45.2115C30.6534 45.3834 30.2764 45.4824 29.8868 45.4979C29.4972 45.5133 29.1096 45.4447 28.7654 45.2993C28.4213 45.1538 28.1334 44.9371 27.9325 44.6721C27.7317 44.4072 27.6254 44.104 27.625 43.795V26.1983C27.6283 25.8904 27.7363 25.5889 27.9376 25.3256C28.1389 25.0622 28.4261 24.8467 28.7689 24.7017C29.1118 24.5568 29.4976 24.4877 29.8858 24.5018C30.274 24.5159 30.6502 24.6126 30.9748 24.7818Z" fill="white" fill-opacity="0.3"></path></clipPath></defs></svg><div class='inner-play-icon' />`
        : `<svg
    version='1.1'
    viewBox='0 0 68 48'
    width='68px'
    style='position: relative;'
    >
    <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
    <path d='M 45,24 27,14 27,34' fill='#fff'></path>
    </svg>`
    }
    </div>`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    {...props}
  />
);
